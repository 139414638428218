import styled from 'styled-components';

export const DoubleImageStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (orientation: landscape) {
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  @media screen and (orientation: landscape) {
    inline-size: 50%;
  }
`;
