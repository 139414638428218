import dynamic from 'next/dynamic';

import { minWidth } from '@hultafors/shared/style';

import {
  DualImageFragment,
  ImageLoadFragment,
} from '@hultafors/eripiowear/types';

import { DoubleImageStyled, ImageContainer } from './dual-image-block.styled';

const DatoImage = dynamic(
  () =>
    import('@hultafors/shared/components').then((module) => module.DatoImage),
  { ssr: false },
);

export interface DualImageBlockProps extends DualImageFragment {
  priority?: boolean;
}

export const DualImageBlock: React.FC<DualImageBlockProps> = ({
  dualImage: images,
  dualImagesLoad,
  priority = false,
}) => {
  const imageMapper = (image: ImageLoadFragment, index: number) => {
    const sizes = [minWidth.mobileMax`50vw`, '100vw'].join(', ');
    return (
      <ImageContainer key={`dual-image-${index}`}>
        <DatoImage
          image={image}
          priority={priority}
          sizes={sizes}
          aspectRatio={1}
        />
      </ImageContainer>
    );
  };
  return (
    <DoubleImageStyled>{dualImagesLoad.map(imageMapper)}</DoubleImageStyled>
  );
};
